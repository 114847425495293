<template>
  <div class="pa-5">
    <v-form ref="formElem">
      <v-text-field
        v-model="form.name"
        label="Name"
        name="name"
        autocomplete="false"
        required
      ></v-text-field>

      <v-text-field
        v-model="form.email"
        label="Email"
        name="email"
        autocomplete="false"
        required
      ></v-text-field>

      <v-text-field
        v-model="form.password"
        :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        name="password"
        hint="At least 8 characters"
        autocomplete="false"
        counter
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-text-field
        v-model="form.password_confirmation"
        :type="showPassword ? 'text' : 'password'"
        label="Confirm Password"
        name="password_confirmation"
        hint="At least 8 characters"
        autocomplete="false"
        counter
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-switch
        v-model="form.active"
        label="Active"
      ></v-switch>

      <v-btn
        block
        color="primary"
        :loading="loading"
        class="text-center"
        :disabled="loading"
        @click="validate"
      >
        {{ mode == 'edit' ? 'Update' : "Create" }}
      </v-btn>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        type="error"
        class="my-2"
      >
        {{ msg }}
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import { required } from '@core/utils/validation'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  name: 'SettingForm',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const mode = props.resource ? 'edit' : 'create'
    const showPassword = ref(false)

    const getInitialData = () => ({
      name: props.resource?.profile.name ?? null,
      email: props.resource?.email ?? null,
      password: null,
      password_confirmation: null,
      active: props.resource?.active ?? true,
    })

    const form = ref({ ...getInitialData() })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    // Reset value when key changed
    watch(
      () => props.resource,
      () => {
        form.value = { ...getInitialData() }
      },
    )

    onMounted(() => {
      //
    })

    return {
      mode,
      showPassword,

      form,
      formElem,

      validate,
      required,

      icons: {
        mdiEye,
        mdiEyeOff,
      },
    }
  },
}
</script>
