import axios from '@axios'

const getUsers = async merchantId => {
  const endPoint = `/admin/merchants/${merchantId}/users`

  const { status, data } = await axios.get(endPoint)

  if (status == 200) {
    return data.data.records
  }

  return null
}

const storeUser = async (merchantId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/users`

  const { status, data } = await axios.post(endPoint, payload)

  if (status == 201) {
    return data.data
  }

  return null
}

const updateUser = async (merchantId, userId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/users/${userId}`

  const { status, data } = await axios.put(endPoint, payload)

  if (status == 200) {
    return data.data
  }

  return null
}

const deleteUser = async (merchantId, userId) => {
  const endPoint = `/admin/merchants/${merchantId}/users/${userId}`

  const { status, data } = await axios.delete(endPoint)

  if (status == 200) {
    return data.data
  }

  return null
}

const restoreUser = async (merchantId, userId) => {
  const endPoint = `/admin/merchants/${merchantId}/users/${userId}/restore`

  const { status, data } = await axios.post(endPoint)

  if (status == 200) {
    return data.data
  }

  return null
}
export { getUsers, storeUser, updateUser, deleteUser, restoreUser }
