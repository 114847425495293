<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <user-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors, useMerchantId } from '@/composables'
import { storeUser, updateUser } from '@api/merchant/user'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserForm from './UserForm.vue'

export default {
  name: 'UserAside',
  components: { UserForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      try {
        const data = { ...form }

        let response = null

        if (props.resource) {
          response = await updateUser(useMerchantId(), props.resource.id, data)
        } else {
          response = await storeUser(useMerchantId(), data)
        }

        if (response) {
          emit('changed', response)
        }
      } catch (error) {
        errors.value = useExtractErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
